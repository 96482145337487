import React from 'react';
import ButtonMUI from '@mui/material/Button';
import { Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {CircularProgress} from '@mui/material';
const Button = ({ buttonText, isLoading,sx, ...props }) => {





    if (isLoading) {
      return (
        <Box className={props.className} sx={sx}>
        <LoadingButton   loadingIndicator={<CircularProgress size={24} sx={{ color: 'white' }} />} {...props} loading>
          {buttonText}
        </LoadingButton>
        </Box>
      );
    } else {

      return (
        <ButtonMUI sx={sx} {...props}>
          {buttonText}
        </ButtonMUI>
      );
    }
  };

  export default Button;
