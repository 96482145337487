import React,{useEffect,useRef} from "react";
import { PolarArea } from "react-chartjs-2";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  PolarAreaController,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the plugin
import { useInView } from "react-intersection-observer"; // For visibility detection

// Register required chart elements and the plugin
ChartJS.register(ArcElement, CategoryScale, PolarAreaController, Tooltip, Legend, ChartDataLabels);

const PolarAreaChart = ({ chartData, legendColor = "text.secondary" }) => {
  const { labels, data } = chartData;
  const chartRef = useRef(null);
  // Intersection Observer hook
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger only once when the component enters the viewport
    threshold: 0.5, // Adjust threshold if needed
  });

  // Calculate total sum for percentage calculation
  const total = data.reduce((sum, value) => sum + value, 0);
  useEffect(() => {
    return () => {
      // Destroy chart instance on unmount
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, []);
  // Generate the dataset dynamically from the passed chartData
  const chartDataFormatted = {
    labels: labels,
    datasets: [
      {
        label: "Data values",
        data: data,
        backgroundColor: [
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
          "rgba(54, 162, 235, 0.6)",
        ],
        borderColor: "transparent",
        borderWidth: 1,
      },
    ],
  };

  // Custom chart options to make it a half-circle
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: { duration: 1500 }, // Set animation duration
    layout: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      r: {
        grid: { display: true },
        ticks: { display: false },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "right",
        align: 'middle', // Align it to the end (right)
        labels: {
          padding: 25,
          boxWidth: 9,
          color: legendColor,
          usePointStyle: true,
        },
      },
      datalabels: {
        display: true,
        color: "#fff",
        formatter: (value) => `${value}%`, // Display value as a percentage
        font: {
          weight: "bold",
          size: 12,
        },
        align: "center",
        anchor: "center",
      },
    },
  };

  return (
    <Card
      className="w-full md:w-1/2 lg:w-1/3"
      style={{
        WebkitBoxShadow: "0px 0px 12px 0px #bdcdd1", // Subtle shadow effect
        maxWidth: "100%",
      }}
    >
      <CardHeader
        title="Polar Area Chart"
        subheader="Chart.js Polar Area Chart Example"
        sx={{

          flexDirection: ["column", "row"],
          alignItems: ["flex-start", "center"],
          "& .MuiCardHeader-action": { mb: 0 },
          "& .MuiCardHeader-content": { mb: [2, 0] },
        }}
      />
      <CardContent className="flex items-center"  sx={ { minHeight:300}} ref={ref}>
        {/* Render chart only when in view */}
        {inView && (
          <PolarArea
          chartRef={chartRef}
            data={chartDataFormatted}
            height={350}
            options={options}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default PolarAreaChart;
