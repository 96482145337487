import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { fetchFinancialDetails } from "../utils/cms";
import { Box, CircularProgress } from "@mui/material";
import { useTranslationsContext } from "../store/context/translations-context";
import { financeAtCheck, insuranceCheck } from "../utils/cms";
import CardMessageButton from "../components/UI/CardMessageButton";
import { useNavigate } from "react-router-dom";

export default function Integrations() {
  const [financialData, setFinancialData] = useState(null);

  const { requestTranslation } = useTranslationsContext();
  const navigate = useNavigate();
  const [lastUpdatedLabel, setLastUpdatedLabel] = useState("");
  const [isTranslationsLoading, setIsTranslationsLoading] = useState(true);

  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const lastUpdatedLbl = await requestTranslation("last-updated");
        setLastUpdatedLabel(lastUpdatedLbl);
      } catch (error) {
        console.error("Error fetching translations:", error);
      } finally {
        setIsTranslationsLoading(false);
      }
    };

    fetchTranslations();
  }, [requestTranslation]);

  useEffect(() => {
    const fetchFinancialDetailsData = async () => {
      try {
        const data = await fetchFinancialDetails();
        setFinancialData(data);
      } catch (error) {
        console.error("Error fetching financial details:", error);
      }
    };

    fetchFinancialDetailsData();
  }, []);

  const handleRefreshFinance = async () => {
    try {
      const response = await financeAtCheck();
      if (response) {
        navigate("/notification", {
          state: {
            messages: {
              title: "at-status-update",
              success: "at-status-update-success",
              instructions: "at-status-update-instructions",
            },
            buttons: [
              {
                text: "back",
                link: "/profile/integrations",
              },
            ],
          },
        });
      }
    } catch (error) {
      console.error("Error triggering finance check:", error);
    }
  };

  const handleRefreshInsurance = async () => {
    try {
      const response = await insuranceCheck();

      if (response) {

        navigate("/notification", {
          state: {
            messages: {
              title: "insurance-status-update",
              success: "insurance-status-update-success",
              instructions: "insurance-status-update-instructions",
            },
            buttons: [
              {
                text: "back",
                link: "/profile/integrations",
              },
            ],
          },
        });
      }
    } catch (error) {
      console.error("Error triggering insurance check:", error);
    }
  };

  if (isTranslationsLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className="flex flex-wrap gap-6 justify-center mt-6 p-6">
      <CardMessageButton
        text="Seguros Unipeople"
        subText={`${lastUpdatedLabel}: ${financialData?.insurance?.last_updated || "N/A"}`}
        buttonText="Atualizar dados"
        icon="fas fa-wallet"
        onClick={handleRefreshInsurance}
      />
      <CardMessageButton
        text="Portal das Finanças"
        subText={
          financialData?.official_status?.last_updated
            ? `${lastUpdatedLabel}: ${format(
                new Date(financialData.official_status.last_updated),
                "yyyy-MM-dd HH:mm:ss"
              )}`
            : "N/A"
        }
        buttonText="Atualizar dados"
        icon="p4l-business-investment text-red-500"
        onClick={handleRefreshFinance}
      />
    </Box>
  );
}
