import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
import { useTranslationsContext } from '../store/context/translations-context';

function NotificationPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { messages, email, buttons, error } = location.state || {}; // Use "buttons" from state instead of a single "button"

  const { requestTranslation } = useTranslationsContext(); // Access TranslationContext

  const [translations, setTranslations] = useState({
    notificationTitle: '',
    instructionsMessage: '',
    successMessage: '',
    buttonLabels: []
  });

  const [loading, setLoading] = useState(true); // State to track loading

  useEffect(() => {
    if (!messages) {
      navigate('/', { replace: true });
    }
  }, [messages, navigate]);

  useEffect(() => {
    const loadTranslations = async () => {
      const notificationTitle = await requestTranslation(messages?.title);
      const instructionsMessage = await requestTranslation(messages?.instructions || '');
      const successMessage = await requestTranslation(messages?.success || '');

      // Map through buttons and translate each button's text
      const buttonLabels = await Promise.all(
        buttons?.map(async (btn) => await requestTranslation(btn.text)) || []
      );

      setTranslations({ notificationTitle, instructionsMessage, successMessage, buttonLabels });
      setLoading(false); // Set loading to false once translations are loaded
    };

    loadTranslations();
  }, [requestTranslation, messages, buttons]);

  const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: '10px 24px',
    fontWeight: 'bold',
    borderRadius: '50px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    margin: '10px', // Add some margin between buttons
  }));

  const CenterBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    flexDirection: 'column',
  });

  return (
    <>
      {loading ? ( // Show loading spinner while translations are being fetched
        <CenterBox>
          <CircularProgress size={50} /> {/* Circular Progress Spinner */}
        </CenterBox>
      ) : (
        messages && (
          <div className='bg-grey'>
            <CenterBox>
              {/* Wrapper for the gradient background */}
              <Box
                sx={{
                  background: 'linear-gradient(to right, #252959 0%, #e62686 100%)',
                  borderRadius: '50%',
                  width: '100px',
                  height: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {/* Conditionally render the check or cross icon */}
                {error ? (
                  <CloseIcon
                    sx={{
                      fontSize: 50, // Adjust the size of the cross
                      color: '#fff', // White color for the cross
                    }}
                  />
                ) : (
                  <CheckIcon
                    sx={{
                      fontSize: 50, // Adjust the size of the checkmark
                      color: '#fff',  // White color for the checkmark
                    }}
                  />
                )}
              </Box>

              <Typography variant="h4" sx={{ fontWeight: 'bold', mt: 4 }}>
                {translations.notificationTitle}
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
                {translations.successMessage}
              </Typography>
              <Typography className='text-primary-accent' variant="body" sx={{ fontWeight: 'bold', mt: 2, color: 'primary.accent' }}>
                {translations.instructionsMessage} {email && <strong>{email}</strong>}
              </Typography>

              {/* Render multiple buttons */}
              <Box sx={{ mt: 4 }}>
                {buttons?.map((btn, index) => (
                  <StyledButton
                    key={index}
                    component={Link}
                    to={btn.link} // Use the button link from the button object
                  >
                    {translations.buttonLabels[index]} {/* Render translated button text */}
                  </StyledButton>
                ))}
              </Box>
            </CenterBox>
          </div>
        )
      )}
    </>
  );
}

export default NotificationPage;
