import React, { useEffect, useRef } from "react";
import { Doughnut } from "react-chartjs-2";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the plugin
import { useInView } from "react-intersection-observer"; // For visibility detection

// Register required chart elements and plugins
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, ChartDataLabels);
const scaleUp = {
  0: 0.55,
  1: 0.65,
  2: 0.70,
  3: 0.55,
};

const gradients = [
  "rgb(52, 46, 61)",
  "rgb(253, 213, 101)",
  "rgb(106, 155, 146)",
  "rgb(66, 99, 103)",
];

const darkerGradients = [
  "rgb(84, 74, 99)",
  "rgb(233, 213, 81)",
  "rgb(86, 135, 126)",
  "rgb(46, 79, 83)",
];

// Custom plugin for drawing external labels and exploded segments
const plugins = [
  {
    id: "explodedChart",

    beforeDatasetDraw(chart, args, options) {
      const { ctx } = chart;
      const datasetMeta = chart.getDatasetMeta(0);

      datasetMeta.data.forEach((arc, index) => {
        // Increase the radius for the specified segment
        if (index in scaleUp) {
          const originalOuterRadius = arc.outerRadius;
          const originalInnerRadius = arc.innerRadius;

          const scaleFactor = scaleUp[index]; // Factor to scale up the segment

          // Temporarily increase the radius for this arc
          arc.outerRadius = originalOuterRadius * scaleFactor;
          arc.innerRadius = 50;

          ctx.save();

          // Set shadow properties
          ctx.shadowColor = "rgba(0, 0, 0, 0.4)";
          ctx.shadowBlur = 10;
          ctx.shadowOffsetX = 5;
          ctx.shadowOffsetY = 5;

          // Draw the arc with scaled radii
          arc.draw(ctx);

          ctx.restore();

          // Restore original radius values to prevent side effects
          arc.outerRadius = originalOuterRadius;
          arc.innerRadius = originalInnerRadius;
          arc.hidden = true; // Hide the arc visually
        }
      });
    },
    afterDatasetsDraw(chart) {
      const { ctx, data } = chart;
      const width = chart.width;
      const height = chart.height;

      // Dynamically set the font size based on chart size
      const fontSize = Math.min(height / 15, width / 20); // Adjust to both width and height

      chart.getDatasetMeta(0).data.forEach((arc, index) => {
        const label = data.labels[index];
        const value = data.datasets[0].data[index];
        const position = arc.tooltipPosition();
        const angle = (arc.startAngle + arc.endAngle) / 2;
        const deltaAngle = Math.abs(arc.startAngle - arc.endAngle);
        const originalOuterRadius = arc.outerRadius;

        arc.hidden = false;

        let offset = 100;
        let deltaY = 0;
        let scale = 1;

        if (value > 0) {
          // Set font dynamically based on chart size
          if (deltaAngle > Math.PI / 3) {
            ctx.font = `bold ${fontSize * 0.6}px sans-serif`; // Bold font
            ctx.fillStyle = "white"; // Value in black
            offset = -30;
            ctx.textAlign = "right";
            deltaY = 30;
            scale = 0.80;
          } else {
            ctx.font = `${fontSize * 0.6}px sans-serif`; // Regular font
            ctx.fillStyle = gradients[index];
            if (angle > -Math.PI / 2) {
              ctx.textAlign = "left";
            } else {
              ctx.textAlign = "right";
            }

            offset = 120;
            deltaY = 6;
            scale = 1.08;
          }

          if (index in scaleUp) {
            scale = scale * scaleUp[index];
          }

          let xOffset = Math.cos(angle) * originalOuterRadius * scale;
          let yOffset = Math.sin(angle) * originalOuterRadius * scale;

          // Draw label text
          ctx.fillText(label, arc.x + xOffset, arc.y + yOffset + deltaY);

          if (deltaAngle > Math.PI / 3) {
            ctx.font = `bold ${fontSize * 0.8}px sans-serif`; // Bold font for value
            ctx.fillStyle = "white";

            offset = 14;
            deltaY = 25;
            scale = 0.95;
          } else {
            ctx.font = `bold ${fontSize * 0.8}px sans-serif`; // Regular font for value
            ctx.fillStyle = "white";
            offset = 60;
            deltaY = 4;
            scale = 0.85;
          }

          if (index in scaleUp) {
            scale = scale * scaleUp[index];
          }

          xOffset = Math.cos(angle) * originalOuterRadius * scale;
          yOffset = Math.sin(angle) * originalOuterRadius * scale;

          ctx.textAlign = "center";
          ctx.fillText(value + "%", arc.x + xOffset, arc.y + yOffset + deltaY); // 26px
        }
      });
    },
  },
];

ChartJS.register(plugins);

const HalfDonutChart = ({ chartData, legendColor = "text.secondary" }) => {
  const { labels, data, backgroundColor } = chartData;
  const chartRef = useRef(null);

  // Intersection Observer hook
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  useEffect(() => {
    return () => {
      // Destroy chart instance on unmount
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, []);

  // Calculate total sum for percentage calculation
  const total = data.reduce((sum, value) => sum + value, 0);

  // Prepare formatted data for the chart
  const chartDataFormatted = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: backgroundColor || [
          "#543A6B", // Custom color for "Contas"
          "#FDD35C", // Custom color for "Poupança"
          "#7ACFC7", // Custom color for "Investimentos"
          "#3E525F", // Custom color for "Caridade"
        ],
        borderWidth: 0,
      },
    ],
  };

  // Custom options to render only half of the donut
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    rotation: -90, // Start angle for half donut
    circumference: 180, // Display only 180° for a half donut

    plugins: {
      legend: {
        display: false, // Disable legend
      },
      tooltip: {
        enabled: false, // Disable tooltips for a clean look
      },

    },
    layout: {
      padding: {
        bottom: 50, // Add padding to position chart better
      },
    },
  };

  return (
    <Card
      className="w-full md:w-1/2 lg:w-1/3"
      style={{
        WebkitBoxShadow: "0px 0px 12px 0px #bdcdd1", // Subtle shadow effect
        maxWidth: "100%",
      }}
    >
      <CardHeader
      title={chartData.title}
      subheader={chartData.subtitle}
        sx={{
          flexDirection: ["column", "row"],
          alignItems: ["flex-start", "center"],
          "& .MuiCardHeader-action": { mb: 0 },
          "& .MuiCardHeader-content": { mb: [2, 0] },
        }}
      />
      <CardContent
        className="flex items-center"
        sx={{ minHeight: 300 }}
        ref={ref}
      >
        {/* Render chart only when in view */}
        {inView && (
          <Doughnut
            ref={chartRef}
            data={chartDataFormatted}
            height={300}
            options={options}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default HalfDonutChart;
