import React, { useState, useEffect } from 'react';
import { Box, Typography, FormGroup, FormControlLabel, Checkbox, FormControl, CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom'; // For navigation
import Form from "../UI/Form"; // Reusable form component
import { getFinancialDocuments } from "../../utils/cms"; // API function
import { useTranslationsContext } from '../../store/context/translations-context';
import { financeAtCheck } from '../../utils/cms';
import P4lIcon from '../UI/P4lIcon';
// Styled component for green check and red cross
const StatusIcon = styled(Box)(({ theme, status }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: status === 'GREEN' ? theme.palette.success.main : theme.palette.error.main,
  fontSize: 50,
  marginBottom: theme.spacing(2),
}));

// Document list with proper IDs
const documentsList = [
  { id: 'BP_mapa_resp', labelKey: 'mapa-responsabilidade' },
  { id: 'PF_imi_cad_predial', labelKey: 'imi-caderneta-predial' },
  { id: 'PF_imi_notas_cobranca', labelKey: 'imi-notas-de-cobranca' },
  { id: 'PF_irs_modelo_3', labelKey: 'irs-modelo-3' },
  { id: 'PF_irs_declaration', labelKey: 'irs-declaration' },
  { id: 'PF_iuc_vehicles', labelKey: 'iuc-vehicles' },
  { id: 'PF_data_vehicles', labelKey: 'data-vehicles' },
  { id: 'PF_tax_domicile', labelKey: 'tax-domicile' },
];

const FinancialStatusValid = ({ officialStatus }) => {
  const { at_status, last_updated } = officialStatus;
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state for form submission
  const [isTranslationsLoading, setIsTranslationsLoading] = useState(true); // Loading state for translations
  const [translatedDocuments, setTranslatedDocuments] = useState([]); // State to store translated document labels
  const [statusMessage, setStatusMessage] = useState('');
  const [lastUpdatedLabel, setLastUpdatedLabel] = useState('');
  const [buttonText, setButtonText] = useState(''); // State for button text
  const [selectAllLabel, setSelectAllLabel] = useState(''); // State for "Select All" label translation
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false); // State for select all checkbox
  const [instructionsMessage, setInstructionsMessage] = useState(''); // State for instructions message
  const navigate = useNavigate(); // To navigate to the notification page
  const { requestTranslation } = useTranslationsContext(); // Access TranslationContext

  // Format the date using date-fns
  const formattedDate = format(new Date(last_updated), 'yyyy-MM-dd HH:mm:ss');

  // Fetch translations
  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const translatedDocs = await Promise.all(
          documentsList.map(async (doc) => ({
            id: doc.id,
            label: await requestTranslation('get-'+doc.labelKey),
          }))
        );

        const statusMsg = at_status === 'GREEN'
          ? await requestTranslation('everything-good')
          : await requestTranslation('issues-with-status');

        const lastUpdatedLbl = await requestTranslation('last-updated');
        const translatedButtonText = await requestTranslation('get-documents'); // Assuming the key is 'get-documents'
        const translatedSelectAllLabel = await requestTranslation('select-all'); // Assuming 'select-all' is the translation key
        const translatedInstructionsMessage = await requestTranslation('at-red-instructions-message'); // Assuming 'instructions-message' is the key
        setTranslatedDocuments(translatedDocs);
        setStatusMessage(statusMsg);
        setLastUpdatedLabel(lastUpdatedLbl);
        setButtonText(translatedButtonText);
        setSelectAllLabel(translatedSelectAllLabel); // Set the translated label for "Select All"
        setInstructionsMessage(translatedInstructionsMessage); // Set the instructions message translation
      } catch (error) {
        console.error('Error fetching translations:', error);
      } finally {
        setIsTranslationsLoading(false); // Mark translations loading as complete
      }
    };

    fetchTranslations();
  }, [requestTranslation, at_status]);

  // Handle checkbox changes
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setSelectedDocs((prev) => [...prev, name]);
    } else {
      setSelectedDocs((prev) => prev.filter((doc) => doc !== name));
    }
  };

  // Handle select all checkbox
  const handleSelectAllChange = (event) => {
    const { checked } = event.target;
    setIsSelectAllChecked(checked);

    if (checked) {
      // Select all documents
      setSelectedDocs(translatedDocuments.map((doc) => doc.id));
    } else {
      // Deselect all documents
      setSelectedDocs([]);
    }
  };

  // Handle form submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedDocs.length > 0) {
      setIsLoading(true); // Start loading

      try {
        // Call the API with the selected documents
        const response = await getFinancialDocuments(selectedDocs);

        if (response) {
          // If API call succeeds, navigate to the notification page
          navigate("/notification", {
            state: {
              messages: {
                title: "documents-requested",
                success: "documents-requested-info",
                instructions: "documents-requested-instructions",
              },
              buttons: [
                {
                  text: "back",
                  link: "/profile/financials",
                },
              ],
            },
          });
        }
      } catch (error) {
        console.log(error)

        navigate("/notification", {
          state: {
            error:true,
            messages: {
              title: "documents-requested-error",
              success: "documents-requested-info-error",
              instructions: "documents-requested-instructions-error",
            },
            buttons: [
              {
                text: "back",
                link: "/profile/financials",
              },
            ],
          },
        });
      } finally {
        setIsLoading(false); // Stop loading
      }
    } else {
      alert("Please select at least one document.");
    }
  };

  const handleRefresh = async () => {
    try {
      const response = await financeAtCheck(); // Trigger financeAtCheck API call
      if (response) { navigate("/notification", {
        state: {
          messages: {
            title: "at-status-update",
            success: "at-status-update-success",
            instructions: "at-status-update-instructions",
          },
          buttons: [
            {
              text: "back",
              link: "/profile/financials",
            },
          ],
        },
      });
    }
    } catch (error) {
      console.error("Error triggering finance check:", error);
    }
  };

  if (isTranslationsLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Form buttonText={buttonText} onSubmit={handleSubmit} isLoading={isLoading}>
      <Box display="flex" flexDirection="column" alignItems="center">
        {/* Display the green check or red cross based on at_status */}
        <StatusIcon status={at_status}>
          {at_status === "GREEN" ? (
            <CheckCircleIcon fontSize="inherit" />
          ) : (
            <CancelIcon fontSize="inherit" />
          )}
        </StatusIcon>

        {/* Display the status message */}
        <div className='flex flex-col gap-4 items-center'>
        <Typography variant="h5" fontWeight="bold">
          {statusMessage}
        </Typography>
        { at_status==='RED' && <Typography variant="h6" >
          {instructionsMessage}
        </Typography>}

        {/* Display the last update date */}
        <div className='flex items-center gap-4'>
        <Typography variant="body1" color="textSecondary">
          {`${lastUpdatedLabel}: ${formattedDate}`}
        </Typography>
        <P4lIcon size="large" icon="p4l-refresh" className="text-4xl" onClick={handleRefresh} />
        </div>
        </div>
        {/* Select All Checkbox */}
        <Box mt={3} width="100%">
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isSelectAllChecked}
                    onChange={handleSelectAllChange}
                  />
                }
                label={selectAllLabel}
                sx={{marginBottom:"1rem"}}
              />
              {translatedDocuments.map((doc) => (
                <FormControlLabel
                  key={doc.id}
                  control={
                    <Checkbox
                      name={doc.id}
                      checked={selectedDocs.includes(doc.id)}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={doc.label}
                  sx={{marginLeft:"2rem"}}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      </Box>
    </Form>
  );
};

export default FinancialStatusValid;
