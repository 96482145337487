import React, { useState, useRef,useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Card, CardHeader, CardContent } from "@mui/material";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the plugin
import { useInView } from "react-intersection-observer"; // Import the hook for Intersection Observer

// Register necessary components from Chart.js
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);
ChartJS.defaults.plugins.legend.display = false;
ChartJS.defaults.plugins.datalabels.display = false;

const DonutChart = ({ chartData }) => {
  const chartRef = useRef(null); // Ref for the chart to access the canvas

  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.5,

  });

  const labels = chartData ? chartData.labels : [];
  const datasetData = chartData ? chartData.data : [];

  const scaleUp = {
    0: 0.55,
    1: 0.65,
    2: 0.70,
    3: 0.55,
  };


  // Gradients for each section
  const gradients = [
    "rgb(52, 46, 61)",
    "rgb(253, 213, 101)",
    "rgb(106, 155, 146)",
    "rgb(66, 99, 103)",
  ];

  const darkerGradients = [
    "rgb(84, 74, 99)",
    "rgb(233, 213, 81)",
    "rgb(86, 135, 126)",
    "rgb(46, 79, 83)",
  ];

  // Function to create gradient
  const setGradient = (c1, c2, ctx) => {
    const gradient = ctx.createRadialGradient(450, 500, 150, 450, 500, 350);
    gradient.addColorStop(0, c1);
    gradient.addColorStop(1, c2);
    return gradient;
  };

  // Chart data structure
  const data = {
    labels: labels,
    datasets: [
      {
        data: datasetData,
        backgroundColor: gradients, // Will be updated dynamically
        hoverBackgroundColor: darkerGradients,
        borderWidth: 0,
      },
    ],
  };

  // Custom plugin to add labels and values to the chart
  const plugins = [
    {
      id: "explodedChart",

      beforeDatasetDraw(chart, args, options) {
        const { ctx } = chart;
        const datasetMeta = chart.getDatasetMeta(0);

        datasetMeta.data.forEach((arc, index) => {
          // Increase the radius for the specified segment
          if (index in scaleUp) {
            const originalOuterRadius = arc.outerRadius;
            const originalInnerRadius = arc.innerRadius;

            const scaleFactor = scaleUp[index]; // Factor to scale up the segment

            // Temporarily increase the radius for this arc
            arc.outerRadius = originalOuterRadius * scaleFactor;
            arc.innerRadius = 50;

            ctx.save();

            // Set shadow properties
            ctx.shadowColor = "rgba(0, 0, 0, 0.4)";
            ctx.shadowBlur = 10;
            ctx.shadowOffsetX = 5;
            ctx.shadowOffsetY = 5;

            // Draw the arc with scaled radii
            arc.draw(ctx);

            ctx.restore();

            // Restore original radius values to prevent side effects
            arc.outerRadius = originalOuterRadius;
            arc.innerRadius = originalInnerRadius;
            arc.hidden = true; // Hide the arc visually
          }
        });
      },
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;
        const width = chart.width;
        const height = chart.height;

        // Dynamically set the font size based on chart size
        const fontSize = Math.min(height / 15, width / 20); // Adjust to both width and height

        chart.getDatasetMeta(0).data.forEach((arc, index) => {
          const label = data.labels[index];
          const value = data.datasets[0].data[index];
          const position = arc.tooltipPosition();
          const angle = (arc.startAngle + arc.endAngle) / 2;
          const deltaAngle = Math.abs(arc.startAngle - arc.endAngle);
          const originalOuterRadius = arc.outerRadius;

          arc.hidden = false;

          let offset = 100;
          let deltaY = 0;
          let scale = 1;

          if (value > 0) {
            // Set font dynamically based on chart size
            if (deltaAngle > Math.PI / 3) {
              ctx.font = `bold ${fontSize * 0.6}px sans-serif`; // Bold font
              ctx.fillStyle = "white"; // Value in black
              offset = -30;
              ctx.textAlign = "center";
              deltaY = 50;
              scale = 0.87;
            } else {
              ctx.font = `${fontSize * 0.6}px sans-serif`; // Regular font
              ctx.fillStyle = gradients[index];
              if (angle > -Math.PI / 2) {
                ctx.textAlign = "left";
              } else {
                ctx.textAlign = "right";
              }

              offset = 120;
              deltaY = 6;
              scale = 1.08;
            }

            if (index in scaleUp) {
              scale = scale * scaleUp[index];
            }

            let xOffset = Math.cos(angle) * originalOuterRadius * scale;
            let yOffset = Math.sin(angle) * originalOuterRadius * scale;

            // Draw label text
            ctx.fillText(label, arc.x + xOffset, arc.y + yOffset + deltaY);

            if (deltaAngle > Math.PI / 3) {
              ctx.font = `bold ${fontSize * 0.8}px sans-serif`; // Bold font for value
              ctx.fillStyle = "white";
              offset = 14;
              deltaY = 35;
              scale = 0.85;
            } else {
              ctx.font = `bold ${fontSize * 0.8}px sans-serif`; // Regular font for value
              ctx.fillStyle = "white";
              offset = 60;
              deltaY = 4;
              scale = 0.85;
            }

            if (index in scaleUp) {
              scale = scale * scaleUp[index];
            }

            xOffset = Math.cos(angle) * originalOuterRadius * scale;
            yOffset = Math.sin(angle) * originalOuterRadius * scale;

            ctx.textAlign = "center";
            ctx.fillText(value + "%", arc.x + xOffset, arc.y + yOffset + deltaY); // 26px
          }
        });
      },
    },
  ];

  // Chart options
  const options = {
    animation: {
      duration: 1500, // Enable animation only when the chart is in view
    },
    rotation: -90, // Start from the top center
    circumference: 180, // Half donut
    cutout: "20%", // Makes it a donut by cutting out the center
    responsive: true,
    maintainAspectRatio: false,
    border: 0,
    layout: {
      padding: {
        top: 0, // Add extra padding at the top
        bottom: 0, // Add extra padding at the bottom
      },
    },
    plugins: {
      legend: {
        display: false, // Explicitly hide the legend here
        responsive: true,
      },
      tooltip: {
        enabled: false, // Disable tooltip
      },
    },
    datalabels: {
      display: false,
    },
    plugins: plugins, // Pass plugins dynamically
  };





  return (
    <Card
      className="w-full md:w-1/2 lg:w-1/3"
      style={{ WebkitBoxShadow: "0px 0px 12px 0px #bdcdd1", maxWidth: "100%" }}
    >
      <CardHeader
        title={chartData.title}
        subheader={chartData.subTitle}
      />
      <CardContent ref={ref} sx={{ padding: "0", margin:"0" }} className="h-[30rem] flex justify-center items-start">

          {inView && <Doughnut ref={chartRef} data={data} options={options} plugins={plugins} />}

      </CardContent>
    </Card>
  );
};

export default DonutChart;
