import { Card, Typography, CardContent, Button, CardActions } from "@mui/material";
import P4lIcon from "./P4lIcon";
import { Link } from "react-router-dom";

function CardMessageButton({ title, text, subText, buttonText, buttonLink, icon,onClick,emoji }) {
  return (
    <Card
      style={{
        WebkitBoxShadow: "0px 0px 12px 0px #bdcdd1", // Adding subtle shadow effect
        maxWidth: "100%",
      }}
      className="p-4 flex flex-col justify-center gap-6   w-full md:w-1/2 lg:w-1/3 xl:w-1/4"

    >
      <CardContent sx={{paddingY:0}} className="flex flex-col gap-6  justify-center " >

            <div className="flex flex-col gap-2 items-center">
              <div className="flex flex-col ">
               { icon &&  <P4lIcon icon={`${icon} text-[2rem]`} size="small" />}
                {emoji && <span className="text-[2rem] text-center">{emoji}</span>}
            <Typography className="min-h-[4rem] flex items-center text-xs" fontWeight={"bold"} variant="subtitle2" sx={{ color: "primary.main", textAlign:"center" ,   fontSize: "0.7rem" }}>
              {text}
            </Typography>
            </div>

            <Typography className="whitespace-nowrap" variant="body2" sx={{ color: "primary.main" }}>
            {subText ? subText : <p className="opacity-0">sem subtitulo</p>}
            </Typography>
            </div>

      </CardContent>

      <CardActions className="mt-auto mx-auto">
      {buttonLink ? (
          <Button size="small" variant="contained">
          <Link to={buttonLink} style={{ textDecoration: "none", color: "inherit" }}>
            {buttonText}
          </Link>
        </Button>
        ) : (
          <Button size="small" variant="contained" onClick={onClick}>
            {buttonText}
          </Button>
        )}

      </CardActions>
    </Card>
  );
}

export default CardMessageButton;
