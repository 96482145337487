import TopNav from './TopNav/TopNav';
import SideNav from './SideNav/SideNav';
import React, {useCallback } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

const Nav = (props) => {
  const theme = useTheme();
  const getSideBarItems = useCallback(() => props.sideBarItems, [props.sideBarItems]);
  const getTopNavItems = useCallback(() => props.topNavItems, [props.topNavItems]);
  return (
    <Box className="" >

      <TopNav
        drawerWidth={props.drawerWidth}
        open={props.open}
        sideBarHandler={props.handleDrawerOpen}
        backgroundColor={theme.palette.primary["100"]}
        color={theme.palette.primary["800"]}
        notifications={props.notifications}
        topNavItems={getTopNavItems()}
      />
      <SideNav
        drawerWidth={props.drawerWidth}
        open={props.open}
        handleDrawerClose={props.handleDrawerClose}
        handleDrawerOpen={props.handleDrawerOpen}
        backgroundColor={theme.palette.primary[100]}
        color={theme.palette.primary["300"]}
        sideBarItems={getSideBarItems()}
      />


    </Box>
  );
};

export default  React.memo(Nav);
