import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, LinearProgress, CircularProgress } from '@mui/material';
import { useTranslationsContext } from '../../store/context/translations-context';

const ProfileProgressBar = ({ progress = 0 }) => {
  const { requestTranslation } = useTranslationsContext(); // Access TranslationContext
  const [translatedText, setTranslatedText] = useState({
    completeProfile: '',
    completedof100:'',
  });
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    const translateLabels = async () => {
      const completeProfile = await requestTranslation('complete-your-profile');
      const completedof100 = await requestTranslation('completed-of-100');
      setTranslatedText({ completeProfile,completedof100 });
      setIsLoading(false); // Set loading to false after translation is fetched
    };


    translateLabels();
  }, [requestTranslation]);

  // Show a loading spinner while translations are being fetched
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Link to={"profile/my-profile"} style={{ textDecoration: 'none' }}>
      <Box className="flex flex-col items-start  w-full" sx={{ position: 'relative' }}>

        <Box className="flex flex-col " sx={{ width: '100%', position: 'relative' }}>


        <Typography
        className='text-right'
            variant="body2"
            sx={{
              fontWeight: 'bold',
              color: 'text.secondary'
            }}
          >
            {`${progress}% ${translatedText.completedof100}`}
          </Typography>

          <LinearProgress
            variant="determinate"
            value={progress}
            style={{ width: '100%', height: "2.5rem" }}
            sx={{
              '& .MuiLinearProgress-bar': {
                backgroundColor: 'rgb(0, 255, 58)', // Completed progress color
                boxShadow:'0px 4px 8px rgba(0, 255, 58, 0.8)'
              },
              backgroundColor: 'rgba(236, 236, 236)', // Not completed progress color
            }}
          />

        </Box>
      </Box>
    </Link>
  );
};

export default ProfileProgressBar;
