import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { red } from '@mui/material/colors';
import AlertMessage from './AlertMessage';
import { useTranslationsContext } from '../../../store/context/translations-context';
import { Padding } from '@mui/icons-material';

const AlertsContainer = ({ issuesCount, steps }) => {
  const { requestTranslation } = useTranslationsContext(); // Access the translation context

  // State to store the translated text and loading status
  const [translatedText, setTranslatedText] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTranslations = async () => {
      const issuesToFix = await requestTranslation('issues-to-fix'); // Assuming 'issues-to-fix' is your translation key
      setTranslatedText(issuesToFix);
      setIsLoading(false); // Set loading to false once translation is fetched
    };


    fetchTranslations();
  }, [requestTranslation]);

  // Show a loading spinner while translations are being fetched
  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Accordion sx={{ bgcolor: "white"}}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ bgcolor: "white", color: red[800] }}
      >
        <Typography className='flex gap-4 items-center' sx={{ color: red[800] }}>
          {issuesCount > 0 && (
            <>
              <span className='p4l-warning3 text-yellow-400 text-5xl'></span>
              {`${issuesCount} ${translatedText}`} {/* Use translation with dynamic count */}
            </>
          )}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding:2 , flexDirection:"column", gap:6}}>
        {issuesCount > 0 &&
          steps.map((alert, index) => (
            <AlertMessage key={index} message={alert.message} action={alert.action} level="danger" />
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default AlertsContainer;
