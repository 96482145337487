import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
function CardButton({ link, iconClass, title }) {
    return (
        <Box
            style={{ WebkitBoxShadow: '0px 0px 12px 0px #bdcdd1' }}
            className="flex flex-col gap-1 w-full items-center justify-center border rounded-lg px-6 py-4 text-black bg-white min-h-40 max-h-40 sm:min-h-36 sm:max-h-36"
        >
            <Link to={'/'+link || '#'} className="flex flex-col gap-2 w-full items-center justify-center">
                <span className={`text-5xl ${iconClass}`}></span>
                <span className="font-bold text-center text-xl text-black">{title}</span>
            </Link>
        </Box>
    );
}

export default CardButton;